import React, { FC, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import classNames from 'classnames';
import * as styles from './quote-block.module.css';

interface QuoteBlockProps {
    quote: string;
    author: string;
    title: string;
    division?: 'design' | 'develop' | 'deploy' | 'innovate' | false;
}

const QuoteBlock: FC<QuoteBlockProps> = ({ quote, author, title, division }) => {
    const quoteBlockEl = useRef<HTMLElement>(null);
    const avatarRef = useRef<HTMLImageElement>(null);
    const quoteTextRef = useRef<HTMLElement>(null);
    const authorRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        // Init gsap
        gsap.registerPlugin(ScrollTrigger);

        const quoteBlockTl = gsap.timeline({
            scrollTrigger: {
                trigger: quoteBlockEl.current,
                markers: false,
                start: 'top 65%',
                toggleActions: 'play pause resume reverse',
            },
        });

        [avatarRef, quoteTextRef, authorRef].forEach((item) => {
            quoteBlockTl.from(
                item.current,
                {
                    y: 20,
                    opacity: 0,
                    ease: 'power2',
                    duration: 0.5,
                },
                '<0.1'
            );
        });
    }, []);

    return (
        <figure ref={quoteBlockEl} className="bg-alchem-dark rounded-3xl p-12 flex gap-6">
            <div className="shrink-0">
                <img
                    ref={avatarRef}
                    src="https://alchemisten.ag/wp-content/uploads/2020/04/theo-alchemistenAG-1-150x150.jpg"
                    alt="Avatar von Theodor Bräutigam"
                    className={classNames('rounded-full', styles.bigAvatarShadow, {
                        [styles.shadowInnovate]: division === 'innovate',
                        [styles.shadowDesign]: division === 'design',
                        [styles.shadowDevelop]: division === 'develop',
                        [styles.shadowDeploy]: division === 'deploy',
                    })}
                />
            </div>
            <div className="flex flex-col justify-center">
                <blockquote ref={quoteTextRef} className="focus-text mb-4">
                    {quote}
                </blockquote>
                <figcaption ref={authorRef}>
                    <p
                        className={classNames('text-lg font-semibold', {
                            'text-alchem-orange': division === 'innovate',
                            'text-alchem-blue': division === 'design',
                            'text-alchem-turquoise': division === 'develop',
                            'text-alchem-green': division === 'deploy',
                            'text-white': !division,
                        })}
                    >
                        {author}
                    </p>
                    <p className="font-semibold text-gray-400">{title}</p>
                </figcaption>
            </div>
        </figure>
    );
};

QuoteBlock.defaultProps = {
    division: false,
};

export default QuoteBlock;
