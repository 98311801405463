import React, { FC, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { GoPlus } from 'react-icons/go';
import { Link } from 'gatsby';
import Heading, { HeadingLevels } from '../heading/heading';

import * as styles from './showcase.module.css';

interface ShowcaseProps {
    title: string;
    caption: string;
    renderSmall: boolean;
    link: string;
    dimmedBackground: boolean;
    headingLevel: HeadingLevels;
}

const Showcase: FC<ShowcaseProps> = ({
    title,
    caption,
    dimmedBackground,
    children,
    headingLevel,
    renderSmall,
    link,
}) => {
    const showcaseContainerRef = useRef<HTMLDivElement>(null);
    const showcaseContentRef = useRef<HTMLDivElement>(null);
    const showcaseCaptionBorderRef = useRef<HTMLHRElement>(null);
    const showcaseTitleRef = useRef<HTMLHeadingElement>(null);
    const showcaseCaptionRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const captionTl = gsap.timeline({
            scrollTrigger: {
                trigger: showcaseContainerRef.current,
                markers: false,
                start: 'top 65%',
                toggleActions: 'play pause resume reverse',
            },
        });

        captionTl.from(showcaseContentRef.current, {
            opacity: 0,
            duration: 0.5,
        });

        captionTl.from(
            showcaseTitleRef.current,
            {
                y: 30,
                duration: 0.5,
                ease: 'power2',
                opacity: 0,
            },
            '<0.1'
        );

        captionTl.from(
            showcaseCaptionRef.current,
            {
                y: 30,
                duration: 0.5,
                ease: 'power2',
                opacity: 0,
            },
            '<0.1'
        );
    }, []);

    return (
        <div ref={showcaseContainerRef} className={styles.showcaseWrapper}>
            <Link to={link} className="h-[36rem] w-full rounded-3xl overflow-hidden bg-alchem-semidark relative">
                <div ref={showcaseContentRef} className="relative w-full h-full">
                    {children}
                </div>

                {dimmedBackground && (
                    <div className="absolute top-0 w-full h-full bg-opacity-80 bg-gradient-to-r from-alchem-semidark to-transparent" />
                )}

                <div className="absolute left-0 px-8 top-10">
                    <div className="max-w-xl">
                        <Heading ref={showcaseTitleRef} level={headingLevel} className="font-bold text-4xl mb-4">
                            {title}
                        </Heading>
                        <p ref={showcaseCaptionRef} className="text-lg font-medium leading-[1.35]">
                            {caption}
                        </p>
                    </div>
                </div>

                <div className="absolute bottom-6 right-6">
                    <div
                        className={`${styles.readMoreButton} bg-white bg-opacity-30 p-4 rounded-full backdrop-blur-xl`}
                    >
                        <GoPlus size={32} />
                    </div>
                </div>

                <div className="absolute top-0 w-full h-full bg-opacity-0 bg-white hover:bg-opacity-5 transition-colors hover:transition-colors" />
            </Link>
        </div>
    );
};

export default Showcase;
