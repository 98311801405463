import React, { FC, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

interface ProgressTrackProps {
    title: string;
    value: number;
}

export const ProgressTrack: FC<ProgressTrackProps> = ({ title, value }) => {
    return (
        <div className="mb-6">
            <div className="progress-track h-4 bg-alchem-orange bg-opacity-20 rounded-full overflow-hidden mb-2">
                <div className="progress-meter bg-alchem-orange h-full" style={{ width: `${value}%` }} />
            </div>
            <p className="font-medium font-xl">{title}</p>
        </div>
    );
};

const ProgressBarWrapper: FC = ({ children }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        document.addEventListener('load', () => {
            ScrollTrigger.refresh();
        });

        if (!wrapperRef?.current?.children) return;

        const progressBars = Array.from(wrapperRef?.current?.children);
        const progressBarTl = gsap.timeline({
            scrollTrigger: {
                trigger: wrapperRef.current,
                markers: true,
                scrub: false,
                start: 'top 75%',
                end: 'bottom top',
                toggleActions: 'play pause resume reverse',
            },
        });

        progressBars.forEach((bar) => {
            const meter = bar.querySelector('.progress-meter');

            progressBarTl.from(
                bar,
                {
                    y: 20,
                    opacity: 0,
                    duration: 0.3,
                },
                '>-0.2'
            );

            progressBarTl.from(
                meter,
                {
                    scaleX: 0.75,
                    transformOrigin: 'left',
                    duration: 0.3,
                },
                '<'
            );
        });
    }, []);

    return (
        <div className="progress-bars" ref={wrapperRef}>
            {children}
        </div>
    );
};

export default ProgressBarWrapper;
