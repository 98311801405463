import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react';
import { globalLocalStorage, windowGlobal } from '@providers/global-page';
import type { CookiesStatic } from 'js-cookie';
import { ConsentCategory, ConsentCategorySetting, ConsentRule, DEFAULT_CONSENT_STORAGE_KEY } from './types';

export interface ConsentType {
    isLoading: boolean;
    shouldViewConsentDialog: boolean;
    settings: ConsentCategorySetting;
    rules: ConsentRule[];
    saveConsent: (consent: Record<ConsentCategory, boolean>, userSaved: boolean) => void;
    setDialogWindow: (visible: boolean) => void;
}

export const SETTINGS_INITIAL_STATE: ConsentCategorySetting = {
    userSaved: false,
    categories: {
        analytics: false,
        preferences: false,
    },
};

export const ConsentContext = createContext<ConsentType>({
    isLoading: true,
    shouldViewConsentDialog: true,
    settings: {
        ...SETTINGS_INITIAL_STATE,
    },
    rules: [],
    saveConsent: () => undefined,
    setDialogWindow: () => undefined,
});

export const useConsentContext = () => {
    return useContext(ConsentContext);
};

export interface ConsentProviderProps {
    rules: ConsentRule[];
    consentStorageKey?: string;
}

export const useCreateConsent = (props: ConsentProviderProps): ConsentType => {
    const cookie = useRef<CookiesStatic | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [rules, setRules] = useState<ConsentRule[]>(props.rules);
    const [shouldViewConsentDialog, setShouldViewConsentDialog] = useState<boolean>(false);
    const [settings, setSettings] = useState<ConsentCategorySetting>({
        ...SETTINGS_INITIAL_STATE,
    });

    const { consentStorageKey = DEFAULT_CONSENT_STORAGE_KEY } = props;

    const saveConsent = (consent: Record<ConsentCategory, boolean>, userSaved: boolean) => {
        setSettings((oldSettings) => {
            const newSettings: ConsentCategorySetting = {
                ...oldSettings,
                userSaved,
                categories: {
                    ...consent,
                },
            };

            if (globalLocalStorage) {
                globalLocalStorage.setItem(consentStorageKey, JSON.stringify(newSettings, null, '\t'));
            }

            return newSettings;
        });
    };

    const setDialogWindow = (view: boolean) => {
        setShouldViewConsentDialog(view);
    };

    useEffect(() => {
        setRules(props.rules);
    }, [props.rules]);

    useEffect(() => {
        if (windowGlobal) {
            setLoading(true);
            // eslint-disable-next-line global-require
            cookie.current = require('js-cookie');
            if (globalLocalStorage) {
                const storedSettingsStr = globalLocalStorage.getItem(consentStorageKey);
                if (storedSettingsStr) {
                    const storedSettings: ConsentCategorySetting = JSON.parse(storedSettingsStr);
                    if (storedSettings.categories) {
                        setSettings(storedSettings);
                        if (!storedSettings.userSaved) {
                            setShouldViewConsentDialog(true);
                        }
                    } else {
                        console.warn('Could not parse settings, categories property is missing', storedSettings);
                    }
                } else {
                    setShouldViewConsentDialog(true);
                }
                setLoading(false);
            } else {
                console.warn('This browser does not implement localStorage');
                setLoading(false);
            }
        }
    }, [consentStorageKey]);

    useEffect(() => {
        if (cookie.current) {
            const allowedCookies = rules.reduce((stack, { cookies, category }) => {
                if (category === 'required' || settings.categories[category]) {
                    for (const c of cookies) {
                        stack.add(c.name);
                    }
                }
                return stack;
            }, new Set());

            for (const [cookieName] of Object.entries(cookie.current?.get() ?? {})) {
                if (!allowedCookies.has(cookieName)) {
                    cookie.current?.remove(cookieName);
                }
            }
        }
    }, [rules, settings]);

    return {
        isLoading,
        shouldViewConsentDialog,
        settings,
        rules,
        saveConsent,
        setDialogWindow,
    };
};

export const ConsentProvider: FC<ConsentProviderProps> = ({ children, ...rest }) => {
    const state = useCreateConsent(rest);

    return <ConsentContext.Provider value={state}>{children}</ConsentContext.Provider>;
};
