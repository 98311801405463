import QuoteBlock from "../../../../src/core/components/quote-block/quote-block";
import { PaginatedSlider } from "../../../../src/core/components/paginated-slider";
import { ImageRender } from "../../../../src/core/components/image-render/image-render";
import { VideoRender } from "../../../../src/core/components/video-render/video-render";
import * as React from 'react';
export default {
  QuoteBlock,
  PaginatedSlider,
  ImageRender,
  VideoRender,
  React
};