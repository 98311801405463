import React, { FC } from 'react';

interface RoundedBlockProps {
    className?: string;
}

const RoundedBlock: FC<RoundedBlockProps> = ({ children, className }) => {
    return <div className={`bg-alchem-dark rounded-3xl p-12 ${className}`}>{children}</div>;
};

export default RoundedBlock;

RoundedBlock.defaultProps = {
    className: '',
};
