import React, { FC, useMemo } from 'react';
import { StaticAssetNode, useStaticAssets } from '@providers/static-assets';

export interface VideosRenderProps {
    src: string;
    videoProps?: React.VideoHTMLAttributes<HTMLVideoElement>;
}

export const VideoRender: FC<VideosRenderProps> = ({ src, videoProps }) => {
    const staticAssets = useStaticAssets();

    const videoAsset = useMemo<StaticAssetNode | null>(() => {
        // Skip external videos
        if (!src || src.startsWith('http')) {
            return null;
        }

        const decodedUrl = decodeURIComponent(src);

        console.log(decodedUrl);
        return staticAssets.assetsMap[decodedUrl] ?? null;
    }, [src, staticAssets.assetsMap]);

    console.log(videoAsset);
    return (
        // eslint-disable-next-line jsx-a11y/media-has-caption,react/jsx-props-no-spreading
        <video className="w-full h-full object-cover" {...videoProps}>
            <source src={videoAsset?.publicURL ?? src} type="video/mp4" />
        </video>
    );
};
