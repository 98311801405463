exports.components = {
  "component---src-layouts-base-index-tsx": () => import("./../../../src/layouts/base/index.tsx" /* webpackChunkName: "component---src-layouts-base-index-tsx" */),
  "component---src-layouts-case-study-index-tsx": () => import("./../../../src/layouts/case-study/index.tsx" /* webpackChunkName: "component---src-layouts-case-study-index-tsx" */),
  "component---src-layouts-focus-index-tsx": () => import("./../../../src/layouts/focus/index.tsx" /* webpackChunkName: "component---src-layouts-focus-index-tsx" */),
  "component---src-layouts-home-index-tsx": () => import("./../../../src/layouts/home/index.tsx" /* webpackChunkName: "component---src-layouts-home-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-openid-callback-tsx": () => import("./../../../src/pages/openid/callback.tsx" /* webpackChunkName: "component---src-pages-openid-callback-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

