import * as styles from "../../../../src/css/pages/home.module.css";
import { FocusCard } from "../../../../src/core/components/focus-card/focus-card";
import RoundedBlock from "../../../../src/core/components/rounded-block/rounded-block";
import { ImageRender } from "../../../../src/core/components/image-render/image-render";
import ClientCws from "../../../../assets/images/index/clients/client_cws.svg";
import ClientEwe from "../../../../assets/images/index/clients/client_ewe.svg";
import ClientEweGo from "../../../../assets/images/index/clients/client_ewe-go.svg";
import ClientFm from "../../../../assets/images/index/clients/client_fm.svg";
import ClientInkoop from "../../../../assets/images/index/clients/client_inkoop.svg";
import ClientUtz from "../../../../assets/images/index/clients/client_utz.svg";
import ClientKampmann from "../../../../assets/images/index/clients/client_kampmann.svg";
import ClientUol from "../../../../assets/images/index/clients/client_uol.svg";
import PartnerBtc from "../../../../assets/images/index/clients/partner_btc.svg";
import PartnerBillwerk from "../../../../assets/images/index/clients/partner_billwerk.svg";
import * as React from 'react';
export default {
  styles,
  FocusCard,
  RoundedBlock,
  ImageRender,
  ClientCws,
  ClientEwe,
  ClientEweGo,
  ClientFm,
  ClientInkoop,
  ClientUtz,
  ClientKampmann,
  ClientUol,
  PartnerBtc,
  PartnerBillwerk,
  React
};