import React from 'react';

import { GatsbyBrowser } from 'gatsby';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { WrapDefaultProviders } from '@providers/wrappers';
import './src/css/index.css';
import { ConsentProvider } from './src/gdpr';
import { ConsentSelector } from './src/gdpr/components/consent-selector';

gsap.registerPlugin(ScrollTrigger);

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }): React.ReactElement => {
    return (
        <WrapDefaultProviders>
            <ConsentProvider
                rules={[
                    {
                        category: 'required',
                        cookies: [
                            {
                                name: '_ga',
                                description: 'Google Analytics',
                                expirationDate: 'Never',
                            },
                        ],
                        description: 'Required Stuff',
                        key: 'google_analytics',
                    },
                ]}
            >
                {element}
                <div className="fixed" style={{ zIndex: 100, bottom: 0, right: 0 }}>
                    <ConsentSelector />
                </div>
            </ConsentProvider>
        </WrapDefaultProviders>
    );
};
