import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { UserManager, WebStorageStateStore } from 'oidc-client';

export interface GlobalPageState {
    authChecked: boolean;
    colorMode: SystemColorSchemes;
    preferredColorMode: Omit<SystemColorSchemes, 'automatic'>;
    setColorMode(mode: SystemColorSchemes): void;
}

// never use window oder other global vars without checking!
export const windowGlobal = typeof window !== 'undefined' && window;
export const globalLocalStorage = typeof localStorage !== 'undefined' && localStorage;

export type SystemColorSchemes = 'automatic' | 'dark' | 'light';

export const colorSchemeMediaQuery = '(prefers-color-scheme: dark)';
export const colorModeStorageKey = '@theme/colorMode';
export const defaultColorSchema = 'automatic';

export const getPreferredColorScheme = (): SystemColorSchemes => {
    if (windowGlobal && windowGlobal.matchMedia) {
        return windowGlobal.matchMedia(colorSchemeMediaQuery).matches ? 'dark' : 'light';
    }

    return 'automatic';
};

export const useCreateGlobalPageState = (): GlobalPageState => {
    const [selectedColorMode, setSelectedColorMode] = useState<SystemColorSchemes>(defaultColorSchema);
    const [preferredColorMode, setPreferredColorMode] = useState<SystemColorSchemes>('light');
    const [authChecked, setAuthChecked] = useState(false);

    useEffect(() => {
        const handleChangeColorMode = (event: MediaQueryListEvent) => {
            setPreferredColorMode(event.matches ? 'dark' : 'light');
        };

        let mediaQueryChangeList: MediaQueryList;
        if (windowGlobal && windowGlobal.matchMedia) {
            mediaQueryChangeList = windowGlobal.matchMedia(colorSchemeMediaQuery);
            mediaQueryChangeList.addEventListener('change', handleChangeColorMode);
            setPreferredColorMode(getPreferredColorScheme());
        }

        if (globalLocalStorage) {
            const initialValue = (globalLocalStorage.getItem(colorModeStorageKey) ?? 'automatic') as SystemColorSchemes;

            setSelectedColorMode(initialValue);
        }

        if (windowGlobal && globalLocalStorage) {
            const userManager = new UserManager({
                authority: 'https://c1.alchemisten.de/auth/realms/alchemisten/.well-known/openid-configuration',
                client_id: 'alchemisten-ag-gatsby',
                redirect_uri: `${windowGlobal.location.origin}/openid/callback`,
                scope: 'openid',
                response_type: 'id_token',
                loadUserInfo: true,
                automaticSilentRenew: true,
                filterProtocolClaims: true,
                userStore: new WebStorageStateStore({
                    prefix: '@auth/keycloak/',
                }),
            });

            userManager.getUser().then((localUser) => {
                if (localUser) {
                    userManager.startSilentRenew();
                    setAuthChecked(true);
                } else if (!windowGlobal.location.pathname.startsWith('/openid/callback')) {
                    globalLocalStorage.setItem('@auth/redirectedFrom', window.location.href);
                    userManager.signinRedirect().then();
                } else {
                    userManager.signinCallback(windowGlobal.location.href).then((newUser) => {
                        userManager.storeUser(newUser).then();
                        const redirectedFrom = globalLocalStorage.getItem('@auth/redirectedFrom');
                        if (redirectedFrom) {
                            windowGlobal.location.replace(redirectedFrom);
                            globalLocalStorage.removeItem('@auth/redirectedFrom');
                        }
                        setAuthChecked(true);
                    });
                }
            });
        } else {
            setAuthChecked(true);
        }

        return () => {
            if (mediaQueryChangeList) {
                mediaQueryChangeList.removeEventListener('change', handleChangeColorMode);
            }
        };
    }, []);

    const setColorMode = (nextColorMode: SystemColorSchemes) => {
        if (globalLocalStorage) {
            globalLocalStorage.setItem(colorModeStorageKey, nextColorMode);
        }
        setSelectedColorMode(nextColorMode);
    };

    return {
        authChecked,
        colorMode: selectedColorMode,
        preferredColorMode,
        setColorMode,
    };
};

export const GlobalPageContext = createContext<GlobalPageState>({
    authChecked: false,
    colorMode: 'automatic',
    preferredColorMode: 'light',
    setColorMode: () => undefined,
});

export const useGlobalPage = () => {
    return useContext(GlobalPageContext);
};

export const GlobalPageProvider: FC = ({ children }) => {
    const globalPageState = useCreateGlobalPageState();

    if (!globalPageState.authChecked) {
        return null;
    }

    return <GlobalPageContext.Provider value={globalPageState}>{children}</GlobalPageContext.Provider>;
};
