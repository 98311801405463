import { Link } from 'gatsby';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useConsentContext } from '../consent-provider';
import { ConsentCategory, ConsentCategorySetting } from '../types';

export interface ConsentSelectorState {
    dirty: boolean;
    settings: ConsentCategorySetting;
}

export const ConsentSelector: FC = () => {
    const { settings, isLoading, saveConsent, shouldViewConsentDialog, setDialogWindow } = useConsentContext();
    const [selectorState, setSelectorState] = useState<ConsentSelectorState>({
        dirty: false,
        settings,
    });

    useEffect(() => {
        setSelectorState({
            dirty: false,
            settings,
        });
    }, [settings]);

    const toggleSetting = useCallback(
        (category: ConsentCategory) => (e: ChangeEvent<HTMLInputElement>) => {
            setSelectorState((prev) => {
                const categories = {
                    ...prev.settings.categories,
                    [category]: e.target.checked,
                };

                return {
                    ...prev,
                    settings: {
                        ...prev.settings,
                        categories,
                    },
                    dirty: !Object.entries(categories).reduce<boolean>((result, [key, value]) => {
                        console.log('Compare', key, settings.categories[key], value);
                        return result && settings.categories[key] === value;
                    }, true),
                };
            });
        },
        [settings]
    );

    const saveSettings = useCallback(() => {
        saveConsent(selectorState.settings.categories, true);
        setSelectorState((prev) => ({
            ...prev,
            dirty: false,
        }));
        setDialogWindow(false);
    }, [saveConsent, selectorState.settings.categories, setDialogWindow]);

    const setAllToValue = useCallback(
        (value: boolean) => () => {
            for (const category of Object.keys(selectorState.settings.categories)) {
                settings.categories[category] = value;
            }
            saveConsent(selectorState.settings.categories, true);
            setSelectorState((prev) => ({
                ...prev,
                dirty: false,
            }));
            setDialogWindow(false);
        },
        [saveConsent, selectorState.settings.categories, setDialogWindow, settings.categories]
    );

    if (!shouldViewConsentDialog) {
        return null;
    }

    return (
        <section className="container rounded-lg w-96 px-8 py-4 mb-8 mr-4 backdrop-blur-lg bg-stone-800 bg-opacity-80">
            <h1 className="text-xl my-2">Cookie Einstellungen</h1>
            <p className="my-2">
                Wir verwenden Cookies, um unsere Website und unseren Service zu optimieren. Statistiken helfen uns,
                stets unser Angebot zu verbessern. Weitere Infos finden Sie unter{' '}
                <Link to="/datenschutz">Datenschutz</Link>
            </p>
            {isLoading && <div>Lade Einstellungen...</div>}
            {settings && (
                <div className="flex justify-between my-2">
                    <label htmlFor="gdpr-required">
                        <input id="gdpr-required" type="checkbox" disabled defaultChecked />
                        <span className="ml-2">required</span>
                    </label>
                    {Object.entries(selectorState.settings.categories).map(([category, value]) => {
                        const labelId = `gdpr-${category}`;
                        return (
                            <label htmlFor={labelId} key={labelId}>
                                <input
                                    id={labelId}
                                    type="checkbox"
                                    checked={value}
                                    onChange={toggleSetting(category as ConsentCategory)}
                                />
                                <span className="ml-2">{category}</span>
                            </label>
                        );
                    })}
                </div>
            )}
            <div className="flex justify-start justify-items-center my-2">
                {selectorState.dirty ? (
                    <>
                        <button
                            type="button"
                            className="outline outline-offset-2 outline-1 outline-alchem-orange p-1 mr-2"
                            onClick={saveSettings}
                        >
                            Einstellungen speichern
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            type="button"
                            className="outline outline-offset-2 outline-1 outline-alchem-orange p-1 mr-4"
                            onClick={setAllToValue(true)}
                        >
                            Alle erlauben
                        </button>
                        <button
                            type="button"
                            className="outline outline-offset-2 outline-1 outline-gray-500 p-1 mr-4"
                            onClick={setAllToValue(false)}
                        >
                            Nur erforderliche
                        </button>
                    </>
                )}
            </div>
        </section>
    );
};
