import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';

import * as style from './paginated-slider.module.css';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

interface PaginatedSliderProps {
    transitionSpeed: number;
    autoplayDelay: number;
    slideData: {
        title: string;
        color: string;
        backgroundColor: string;
    }[];
}

const PaginatedSlider: FC<PaginatedSliderProps> = ({ slideData }) => {
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [currentButton, setCurrentButton] = useState<Element>();

    const swiperRef = useRef() as any; // Workaround: https://github.com/nolimits4web/swiper/issues/5241
    const swiperNavRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const slideButton = swiperNavRef.current?.children?.item(currentSlide);
        if (!slideButton) return;

        console.log(style.slideButtonActive);
        currentButton?.classList.remove(style.slideButtonActive);
        slideButton?.classList.add(style.slideButtonActive);

        setCurrentButton(slideButton);
    }, [currentSlide, currentButton]);

    const slideButtonClickHandler = (index: number, event: MouseEvent<HTMLButtonElement>) => {
        // Set new slide number and move slider
        setCurrentSlide(index);
        swiperRef.current.slideTo(index);
    };

    return (
        <div className="relative">
            <Swiper
                slidesPerView={1}
                effect="fade"
                modules={[EffectFade]}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                }}
                onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
            >
                {slideData.map((slide) => {
                    return (
                        <SwiperSlide key={slide.title}>
                            <div className="relativ h-[42rem] bg-alchem-semidark">
                                <img
                                    src={`https://picsum.photos/seed/${slide.title}/1200/650`}
                                    alt="one"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <div className="grid grid-cols-3 gap-1" ref={swiperNavRef}>
                {slideData.map((slide, index) => (
                    <div key={slide.title} className={style.slideButton}>
                        <button
                            onClick={(event) => {
                                slideButtonClickHandler(index, event);
                            }}
                            type="button"
                            className="w-full block"
                        >
                            <div className={`text-xl ${slide.color} font-semibold py-4 text-left`}>{slide.title}</div>
                            <div className="slide-track h-[3px] relative bg-white bg-opacity-10 overflow-hidden">
                                <div className={`h-full ${style.slideMeter} origin-left ${slide.backgroundColor}`} />
                            </div>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PaginatedSlider;
