import React, { FC } from 'react';
import { Link, navigate } from 'gatsby';
import { MdChevronRight as IconArrowRight } from 'react-icons/md';
import classNames from 'classnames';
import { ImageRender } from '@components/image-render/image-render';
import * as styles from './focus-card.module.css';

interface FocusCardProps {
    categoryTitle: string;
    title: string;
    link: string;
    image: string;
    wrapperClassNames?: string;
    reversed?: boolean;
}

export const FocusCard: FC<FocusCardProps> = ({
    categoryTitle,
    title,
    reversed,
    link,
    wrapperClassNames,
    image,
    children,
}) => {
    const navigateToLink = () => {
        navigate(link);
    };

    return (
        <div
            className={classNames(wrapperClassNames, styles.focusCard, {
                'flex-row-reverse': reversed,
            })}
        >
            <div onClick={navigateToLink} className="flex w-full h-full cursor-pointer" role="presentation">
                <div className="w-3/5 p-12 flex flex-col justify-between">
                    <div>
                        <div className="flex flex-col-reverse">
                            <h3 className="text-4xl font-semibold mb-7 leading-[1.15]">{title}</h3>
                            <p className="uppercase font-semibold tracking-widest text-alchem-orange mb-2">
                                {categoryTitle}
                            </p>
                        </div>
                        <p className="font-medium text-lg">{children}</p>
                    </div>
                    <div className="">
                        <Link
                            to={link}
                            className="standard-button font-semibold inline-flex gap-2 pt-2 pb-2 pl-2 pr-4 rounded"
                        >
                            <IconArrowRight size={24} />
                            Mehr erfahren
                        </Link>
                    </div>
                </div>

                <div className="w-2/5">
                    <ImageRender src={image} alt={title} className="object-cover w-full h-full" />
                </div>
            </div>
        </div>
    );
};

FocusCard.defaultProps = {
    wrapperClassNames: '',
    reversed: false,
};
