export const DEFAULT_CONSENT_STORAGE_KEY = '@gdpr/consent';

export type ConsentCategory = 'preferences' | 'analytics';

export interface ConsentCookieDescription {
    name: string;
    description: string;
    expirationDate: string;
}

export interface ConsentRule {
    key: string;
    category: ConsentCategory | 'required';
    description: string;
    cookies: ConsentCookieDescription[];
}

export interface ConsentCategorySetting {
    userSaved: boolean;
    categories: Record<ConsentCategory, boolean>;
}
