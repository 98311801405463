import React, { createContext, FC, useContext, useMemo } from 'react';
import { File, Query } from '@generated/graphql';

export type AllFileQuery = Query['allFile'];

export type StaticAssetNode = {
    publicURL?: string | null | undefined;
    relativePath: string;
    childImageSharp?: { id: string; gatsbyImageData: any } | null | undefined;
};

export interface StaticAssetsState {
    assets: StaticAssetNode[];
    assetsMap: Record<string, StaticAssetNode>;
}

export type StaticAssetsProviderProps = Pick<StaticAssetsState, 'assets'>;

// Hook for building the asset state from the data coming from the API
export const useCreateStaticAssetsState = ({ assets }: StaticAssetsProviderProps): StaticAssetsState => {
    console.log({ assets });
    const assetsMap = useMemo<Record<string, StaticAssetNode>>(() => {
        return assets.reduce((stack, asset) => {
            // eslint-disable-next-line no-param-reassign
            stack[asset.relativePath] = asset;
            return stack;
        }, {});
    }, [assets]);

    return {
        assets,
        assetsMap,
    };
};

// Context for storing the assets
export const StaticAssetsContext = createContext<StaticAssetsState>({
    assets: [],
    assetsMap: {},
});

// Component for wrapping the provider
export const StaticAssetsProvider: FC<StaticAssetsProviderProps> = ({ children, ...props }) => {
    // Creating a StaticAssetState object from the assets passed in
    const staticAssets = useCreateStaticAssetsState(props);

    // providing the assets data down to component tree
    return <StaticAssetsContext.Provider value={staticAssets}>{children}</StaticAssetsContext.Provider>;
};

// Hook for getting the current context value for the static asset context, simple wrapper for useContext
export const useStaticAssets = () => {
    return useContext(StaticAssetsContext);
};
