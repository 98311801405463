import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

export type HeadingLevels = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadingProps extends ComponentPropsWithoutRef<HeadingLevels> {
    level: HeadingLevels;
}

type CombinedProps = HeadingProps & ComponentPropsWithoutRef<HeadingLevels>;

const Heading = forwardRef<HTMLHeadingElement, CombinedProps>((props, ref) => {
    return React.createElement(props.level, { ref, ...props }, props.children);
});

export default Heading;
