import React, { FC, useMemo } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike, MainImage } from 'gatsby-plugin-image';
import { StaticAssetNode, useStaticAssets } from '@providers/static-assets';
import { GatsbyImageProps } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

export interface ImageRenderProps {
    alt: string;
    className?: string;
    objectFit?: GatsbyImageProps['objectFit'];
    src: string;
}

export const ImageRender: FC<ImageRenderProps> = ({ src, alt, className, objectFit }) => {
    const staticAssets = useStaticAssets();

    const imageAsset = useMemo<StaticAssetNode | null>(() => {
        // Skip external images
        if (!src || src.startsWith('http')) {
            return null;
        }

        const decodedUrl = decodeURIComponent(src);

        console.log(decodedUrl);
        return staticAssets.assetsMap[decodedUrl] ?? null;
    }, [src, staticAssets.assetsMap]);

    const imageData = useMemo<IGatsbyImageData | undefined>(() => {
        if (imageAsset) {
            return getImage(imageAsset as never as ImageDataLike);
        }
    }, [imageAsset]);

    console.log({ imageData, staticAssets });

    if (!imageData) {
        return <MainImage src={imageAsset?.publicURL ?? src} alt={alt} className={className} />;
    }

    return <GatsbyImage image={imageData} alt={alt} className={className} objectFit={objectFit} />;
};
