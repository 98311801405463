import React, { FC, ReactElement } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MarkdownLink } from '@components/markdown-link/markdown-link';
import { GlobalPageProvider } from './global-page';

export const WrapDefaultProviders: FC = ({ children }): ReactElement => {
    return (
        <GlobalPageProvider>
            <MDXProvider
                components={{
                    a: MarkdownLink,
                }}
            >
                {children}
            </MDXProvider>
        </GlobalPageProvider>
    );
};
