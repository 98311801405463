import React, { FC } from 'react';
import { Link } from 'gatsby';
import * as styles from './markdown-link.module.css';

export interface MarkdownLinkProps {
    href: string;
}

export const MarkdownLink: FC<MarkdownLinkProps> = (props) => {
    const { href, children } = props;
    return (
        <Link className={styles.Link} to={href}>
            {children}
        </Link>
    );
};
